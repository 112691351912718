import * as React from "react"
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

import UpdateContentArea from '../templates/update-content-area'

export default function UpdatePage({ data, pageContext }) {
  console.log({data})
  const layoutPieces = data.contentfulNewFeaturesPageTemplate1.pageComponents
  return (
    <Layout>
      <UpdateContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <title>{data?.contentfulNewFeaturesPageTemplate1?.metaTitle}</title>
    <meta name="description" content={data?.contentfulNewFeaturesPageTemplate1?.metaDescription} />
    <meta property="og:title" content={data?.contentfulNewFeaturesPageTemplate1?.ogMetaTitle ? data.contentfulNewFeaturesPageTemplate1.ogMetaTitle : data?.contentfulNewFeaturesPageTemplate1?.metaTitle} />
    <meta property="og:description" content={data?.contentfulNewFeaturesPageTemplate1?.ogMetaDescription ? data.contentfulNewFeaturesPageTemplate1.ogMetaDescription : data?.contentfulNewFeaturesPageTemplate1?.metaDescription} />
    <meta property="og:type" content={data?.contentfulPage?.ogMetaType} />
    <meta property="og:url" content="https://www.entrata.com/product-updates" />
    <meta property="og:image" content={data?.contentfulPage?.ogMetaImage?.image?.url ? data.contentfulPage.ogMetaImage.image.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query ProductUpdateContent {
    contentfulNewFeaturesPageTemplate1 {
      id
      metaDescription
      metaTitle
      ogMetaDescription
      ogMetaTitle
      ogMetaImage {
        image {
          url
        }
      }
      pageComponents {
        ... on Node {
          internal {
            type
          }
        }
        ... on ContentfulDuplexFeature {
          ...DuplexFeature
        }
        ... on ContentfulNewFeaturesIntroComponent {
          ...NewFeatureIntro
        }
        ... on ContentfulNewFeaturesListComponent {
          ... NewFeatureList
        }
        ... on ContentfulPortfolioCarousel {
          ...ClientFeature
        }
        ... on ContentfulQuoteDuplex {
          ...QuoteDuplex
        }
        ... on ContentfulVideoFeature {
          ...VideoFocus
        }
      }
    }
  }
`
