import React from 'react'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import '../styles/new-features.css'

export default function FeatureList({ layoutPiece }) {
  const NewFeatures = layoutPiece?.newFeatureItems
  return (
    <div className="new-feature-list standard-component-spacing">
      <div className="content-cap">
        <div className="new-features-list-intro">
          {layoutPiece.headlineText ?
            <h3>{layoutPiece?.headlineText}</h3>
            : ""
          }
        </div>
        {NewFeatures ?
          <div className="new-features-grid">

            {NewFeatures.map((newFeature, index) => {
              return(
                <div key={index} className="new-features-item">
                  <p className="new-features-item-headline">{newFeature?.headlineText}</p>
                  <p className="new-features-item-subtext">{newFeature?.bodyText?.bodyText}</p>
                </div>
              )
            })}
          </div>
        : ""
        }

      </div>
    </div>
  );
}

export const query = graphql`
  fragment NewFeatureList on ContentfulNewFeaturesListComponent {
    id
    newFeatureItems {
      bodyText {
        bodyText
      }
      headlineText
    }
    headlineText
  }
`
