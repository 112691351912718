import { Link, graphql } from 'gatsby'
import React from 'react'
import '../styles/video.css'
import WistiaThumbnail from './wistia-thumbnail'

export default function VideoFocus({ layoutPiece }) {
  return (
    <div className="field-event-video-focus field-event-pages light-background standard-component-spacing">
      <div className="content-cap">
        <div className="centered-video-holder">
          <WistiaThumbnail className="centered-video-holder-screen" videoId={layoutPiece.wistiaVideoId} />
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment VideoFocus on ContentfulVideoFeature {
    id
    wistiaVideoId
    title
  }
`