import React from 'react'
import { Link, graphql } from 'gatsby'
import '../styles/global-duplex-banner.css'
import { symbolResize, checkSvg } from '../helper-functions'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

export default function GlobalDuplexBanner({ layoutPiece }) {
  const gatsbyImage = getImage(layoutPiece?.image?.image)
  const image = layoutPiece?.image?.image
  const button = layoutPiece?.button 
  const infoBar = layoutPiece?.infoHighlightBar

  function GetDuplexBackground() {
    switch(layoutPiece?.backgroundColor){
      case 'Black':
        return "global-duplex-banner dark-background background-component-spacing"
      case 'Grey':
        return "global-duplex-banner grey-background background-component-spacing"
      case 'Off-white':
        return "global-duplex-banner eggshell-background background-component-spacing"
      default:
        return "global-duplex-banner standard-component-spacing"
    }
  }
  function GetDuplexAlignment() {
    switch(layoutPiece?.duplexAlignment){
      case 'left text':
        return 'left-text-grid'
      default:
        return 'right-text-grid'
    }
  }
  const duplexAlignment = GetDuplexAlignment()

  return (
    <div className={GetDuplexBackground()}>
      <div className="content-cap">
        <div className={duplexAlignment} style={ layoutPiece?.width ? {width: `${layoutPiece?.width}`, margin: 'auto'} : {width: '100%'} }>
          {layoutPiece?.duplexAlignment && layoutPiece?.duplexAlignment === 'right text' ?
            <div className={layoutPiece?.image?.fitToFrame ? "image-contain" : "right-text-image"}>
              {
                layoutPiece.image ?
                  checkSvg(image?.url) ?
                    <img src={image?.url} alt={image?.description} className="duplex-image" />
                    :
                    <GatsbyImage image={gatsbyImage} alt={image?.description} className="duplex-image" />
                  : ""
              }
            </div>
          : ""}
          <div className="split-content">
            <div className="split-content-align">
              {
                layoutPiece?.flag ?
                  <h3>{layoutPiece?.flag}</h3>
                  :""
              }
              <h2>{symbolResize(layoutPiece?.headlineText)}</h2><br/>
              <ReactMarkdown className="markdown">{layoutPiece?.bodyText?.bodyText}</ReactMarkdown>
              {
                button && button?.fullUrl && layoutPiece.backgroundColor === 'Black' ?
                  <a className="button-default solid-light-button" href={button?.fullUrl}>{button?.buttonText}</a>
                :
                button && button?.fullUrl ?
                  <a className="button-default solid-dark-button" href={button?.fullUrl}>{button?.buttonText}</a>
                : ""
              }
              {
                button && button?.slug && layoutPiece.backgroundColor === 'Black' ?
                  <Link className="button-default solid-light-button" to={button?.slug}>{button?.buttonText}</Link>
                :
                button && button?.slug ?
                  <Link className="button-default solid-dark-button" to={button?.slug}>{button?.buttonText}</Link>
                : ""
              }
            </div>
          </div>
          {layoutPiece?.duplexAlignment && layoutPiece?.duplexAlignment === 'left text' ?
            <div className={layoutPiece?.image?.fitToFrame ? "image-contain" : "left-text-image"}>
              {
                layoutPiece.image ?
                  checkSvg(image?.url) ?
                    <img src={image?.url} alt={image?.description} className="duplex-image" />
                    :
                    <GatsbyImage image={gatsbyImage} alt={image?.description} className="duplex-image" />
                  : ""
              }
            </div>
          : ""}
        </div>
      </div>
      {
        infoBar ? 
          <div className={duplexAlignment === "left-text-grid" ? "duplex-info-bar-left" : "duplex-info-bar-right"}>
            <div className="duplex-info-bar-content">
              {
                infoBar?.map((item) => {
                  return (
                    <div className="duplex-info-bar-text">
                      <h3>{item?.headlineText}</h3>
                      <p>{item?.bodyText}</p>
                    </div>
                  )
                })
              }
            </div>
          </div>
        : ""
      }
    </div>
  );
}

export const query = graphql`
  fragment DuplexFeature on ContentfulDuplexFeature {
    id
    backgroundColor
    width
    flag
    bodyText {
      bodyText
    }
    headlineText
    duplexAlignment
    button {
      buttonOutlined
      buttonText
      fullUrl
      slug
    }
    image {
      fitToFrame
      image {
        gatsbyImageData(width: 800, placeholder: BLURRED)
        url
        description
      }
    }
    infoHighlightBar {
      headlineText
      bodyText
    }
  }
`
