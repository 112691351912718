import React, { useEffect } from 'react'
import VideoButton from '../assets/video-button.svg'

export default function WistiaLinkQuoteDuplex (props) {
    useEffect(() => {
        const script1 = document.createElement("script")
        const script2 = document.createElement("script")

        script1.src = "https://fast.wistia.com/embed/medias/videolink.jsonp"
        script1.defer = true;

        script2.src = "https://fast.wistia.com/assets/external/E-v1.js"
        script2.defer = true;

        document.body.appendChild(script1)
        document.body.appendChild(script2)
    }, [])

    return (
        <>
            <span className={`wistia_embed wistia_async_${props.videoId} popover=true popoverContent=link video-button-text`} style={{display: 'block', position: 'relative'}}><a id={props.videoId} style={{textDecoration: 'none', color: `${props.textColor}`}} href={`#wistia_${props.videoId}`}>{props.text}</a>
            <VideoButton className="video-button" />
            </span>
        </>
    )
}