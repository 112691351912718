import React from 'react'
import { Link, graphql } from 'gatsby'
import LinkArrowLight from '../assets/link-arrow-light.svg'
import LinkArrow from '../assets/link-arrow.svg'
import VideoButton from '../assets/video-button.svg'
import '../styles/quote-feature.css'
import ReactMarkdown from 'react-markdown'
import WistiaLinkQuoteDuplex from './wistia-link-quote-duplex'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { checkSvg } from '../helper-functions'

export default function QuoteFeature({ layoutPiece }) {
  const findBackgroundImage = layoutPiece?.image?.image?.resize?.src
  const setBackgroundImage = {
    backgroundImage: 'url(' + findBackgroundImage + ')',
  }
  const ImageCaptions = layoutPiece?.imageCaption
  const gatsbyImage = getImage(layoutPiece?.clientLogo?.image)
  const logoImage = layoutPiece?.clientLogo?.image
  const link = layoutPiece?.link
  const mediaLinkButton = layoutPiece?.mediaLinkButton

  function GetBackgroundColor() {
    switch (layoutPiece?.backgroundColor) {
      case 'Black':
        return "quote-feature dark-background background-component-spacing"
      case 'Grey':
        return "quote-feature grey-background background-component-spacing"
      case 'Off-white':
        return "quote-feature eggshell-background background-component-spacing"
      default:
        return "quote-feature standard-component-spacing"
    }
  }
  
  return (
    <div className={GetBackgroundColor()}>
      <div className="content-cap">
        <div className="quote-feature-grid">
          <div className="quote-content">
            <div className="quote-align">
              <div className="quote-credit-logo">
                {
                  logoImage ?
                    checkSvg(logoImage?.url) ?
                      <img src={logoImage?.url} alt={logoImage?.description} className="quote-logo" />
                      :
                      <GatsbyImage image={gatsbyImage} alt={logoImage?.description} className="quote-logo" />
                    : ""
                }

              </div>
              <ReactMarkdown className="markdown">{layoutPiece.quoteText.quoteText}</ReactMarkdown>
              {
                link && link?.fullUrl && layoutPiece.backgroundColor === 'Black' ?
                  <a className="cta-link-default light-cta-link" href={link?.fullUrl}>{link?.text}<span className="link-arrow-icon"><LinkArrowLight /></span></a>
                :
                link && link?.fullUrl ?
                  <a className="cta-link-default dark-cta-link" href={link?.fullUrl}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></a>
                : ""
              }
              {
                link?.slug && layoutPiece?.backgroundColor === 'Black' ?
                  <Link className="cta-link-default light-cta-link" to={link?.slug}>{link?.text}<span className="link-arrow-icon"><LinkArrowLight /></span></Link>
                :
                link && link?.slug ?
                  <Link className="cta-link-default dark-cta-link" to={link?.slug}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></Link>
                : ""
              }
            </div>
          </div>
          <div className={layoutPiece?.image?.fitToFrame ? "quote-image image-contain" : "quote-image"} style={setBackgroundImage}>
            <div className="quote-image-overlay">
              {mediaLinkButton?.wistiaVideoId ?
                <div>
                  <WistiaLinkQuoteDuplex videoId={mediaLinkButton?.wistiaVideoId} text={mediaLinkButton?.mediaLinkCaption} />
                </div>
                : ""
              }
              <div className="client-info">
                {ImageCaptions?.map((imageCaption, index) => {
                  return(
                    <div key={index}>{imageCaption}</div>
                  )
                })}
              </div>
            </div>
            <div className="quote-gradient"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment QuoteDuplex on ContentfulQuoteDuplex {
    id
    backgroundColor
    clientLogo {
      image {
        gatsbyImageData(width: 300, placeholder: BLURRED)
        resize(width: 300) {
          src
        }
        url
        description
      }
    }
    image {
      image {
        resize(width: 600) {
          src
        }
        url
        description
      }
    }
    imageCaption
    link {
      text
      fullUrl
      slug
    }
    mediaLinkButton {
      mediaLinkCaption
      mediaLinkUrl
    }
    quoteText {
      quoteText
    }
  }
`
