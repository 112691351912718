import React from 'react'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import '../styles/new-features.css'

export default function NewFeaturesIntro({ layoutPiece }) {
  const image = getImage(layoutPiece?.image?.image)
  return (
    <div className="new-feature-list standard-component-spacing">
      <div className="content-cap">
        <section className="new-features-landing generic-align">
          <h1>{layoutPiece?.headlineText}</h1>
          <ReactMarkdown className="update-intro-text">{layoutPiece?.bodyText?.bodyText}</ReactMarkdown>
        </section>
        <div className="new-features-list-intro">
          <h3>{layoutPiece?.releaseDate}</h3>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment NewFeatureIntro on ContentfulNewFeaturesIntroComponent {
    id
    bodyText {
      bodyText
    }
    headlineText
    image {
      image {
        gatsbyImageData(placeholder: BLURRED, width: 800)
        url
        description
      }
    }
    releaseDate
  }
`
