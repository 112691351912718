import * as React from "react"
//import component list
import NewFeaturesIntro from '../components/new-features-intro'
import NewFeaturesList from '../components/new-features-list'
import GlobalDuplexBanner from '../components/global-duplex-banner'
import ClientFeatureSlider from '../components/client-feature'
import QuoteFeature from '../components/quote-feature'
import VideoFeature from '../components/video-focus'

const ContentArea = ({layoutPieces}) => {
    console.log({layoutPieces})
    if (layoutPieces) {
      return layoutPieces?.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulNewFeaturesIntroComponent' && <NewFeaturesIntro layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulNewFeaturesListComponent' && <NewFeaturesList layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <GlobalDuplexBanner layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulPortfolioCarousel' && <ClientFeatureSlider layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulQuoteDuplex' && <QuoteFeature layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulVideoFeature' && <VideoFeature layoutPiece={layoutPiece} key={index}/>,
        ])
      })
    }
}

export default ContentArea
